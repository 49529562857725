// base
export const ROUTE_BASE_ROOT = '/'; // *WARN: DO NOT change this
export const ROUTE_BASE_NAME = '/'; // *WARN: DO NOT change this
export const ROUTE_WILDCARD = '*';

export const ROUTE_LOGIN = 'login';
export const ROUTE_HOME = 'home';
export const ROUTE_MASTERS ='masters';

//Division
export const ROUTE_DIVISION ='division';
export const ROUTE_ADD_DIVISION = 'division/adddivision';
export const ROUTE_UPDATE_DIVISION = 'division/:id/edit';

//Brand
export const ROUTE_BRAND ='brand';
export const ROUTE_ADD_NEW = 'brand/addbrand';
export const ROUTE_UPDATE_BRAND = 'brand/:id/edit';

//Job Role
export const ROUTE_JOB_ROLE = 'jobrole';
export const ROUTE_ADD_JOB_ROLE = 'jobrole/addjob';
export const ROUTE_UPDATE_JOB_ROLE = 'jobrole/:id/edit';

//Budget type
export const ROUTE_BUDGET_TYPE = 'budget';
export const ROUTE_ADD_BUDGET_TYPE = 'budget/addbudget';
export const ROUTE_UPDATE_BUDGET_TYPE = 'budget/:id/edit';

//Freeze Allocation
export const ROUTE_FREEZE_ALLOCATION = 'freezeallocation';
export const ROUTE_ADD_FREEZE_ALLOCATION = 'freezeallocation/addfreezeallocation';
export const ROUTE_UPDATE_FREEZE_ALLOCATION = 'freezeallocation/:id/edit';

//Documents
export const ROUTE_DOCUMENTS = 'documents';
export const ROUTE_ADD_DOCUMENTS = 'documents/adddocuments';
export const ROUTE_UPDATE_DOCUMENTS = 'documents/:id/edit';
export const ROUTE_VIEW_DOCUMENTS = 'documents/:id/view';

//Event Configurations
export const ROUTE_EVENT_CONFIGURATION = 'eventConfigurations';

export const ROUTE_ADD_EVENT_CONFIGURATION = 'eventConfigurations/addEventConfigurations';

export const ROUTE_EDIT_EVENT_CONFIGURATION = 'eventConfigurations/:id/edit';

export const ROUTE_VIEW_EVENT_CONFIGURATION = 'eventConfigurations/:id/view';

//Approval chain definition
export const ROUTE_APPROVAL_CHAIN_DEFINITION_CONFIGURATION = 'approvalChainConfigurations';
export const ROUTE_ADD_APPROVAL_CHAIN_DEFINITION_CONFIGURATION = 'approvalChainConfigurations/addApprovalChainConfigurations';

export const ROUTE_EDIT_APPROVAL_CHAIN_DEFINITION_CONFIGURATION = 'approvalChainConfigurations/:id/edit';

//Uploads
export const ROUTE_UPLOADS = 'upload';
export const ROUTE_PREVIEW_UPLOADS = 'upload/previewupload';

//My Team
export const ROUTE_MY_TEAM = 'myteam';

//My Budget
export const ROUTE_MY_BUDGET = 'mybudget';

//pull back budget
export const ROUTE_PULL_BACK_BUDGET = 'pullBackBudget';

//add pull back request
export const ROUTE_ADD_PULL_BACK_BUDGET = 'pullBackBudget/AddPullBackBudget';

//config master
export const CONFIGURATION_MASTER = 'configurationMaster';

//Request Within Brand
export const ROUTE_REQUEST_WITHIN_BRAND = 'requestwithinbrand';
export const ROUTE_ADD_REQUEST_WITHIN_BRAND = 'requestwithinbrand/addNewRequest';
export const ROUTE_VIEW_REQUEST_WITHIN_BRAND = 'requestwithinbrand/:id/view';

//View Request
export const ROUTE_VIEW_REQUEST = 'viewrequest';
export const ROUTE_EDIT_VIEW_REQUEST = 'viewrequest/:id/edit';

//Reallocate
export const ROUTE_REALLOCATE = 'reallocate';

export const ROUTE_SEARCH_HCP = 'searchhcp';
export const ROUTE_ADD_HCP = 'searchhcp/addhcp';
export const ROUTE_EDIT_HCP = 'searchhcp/:id/edit';
export const ROUTE_VIEW_HCP = 'searchhcp/:id/view';
export const ROUTE_EDIT_HCP_REGISTRATION = 'searchhcp/:id/edithcp';
export const ROUTE_VIEW_HCP_WITH_TAB_PARAM = 'searchhcp/:id/view/:selectedtab';
//Create Events

/*export const ROUTE_CLOSE_EVENT = 'closeevent';
export const ROUTE_VIEW_CLOSE_EVENT = 'closeevent/view';
export const ROUTE_REJECTED_CLOSE_EVENT = 'closeevent/:id/rejected'; */

//Location
export const ROUTE_LOCATION = 'location';
export const ROUTE_ADD_LOCATION = 'location/addlocation';
export const ROUTE_EDIT_LOCATION = 'location/:id/edit';

//User
export const ROUTE_USER = 'user';
export const ROUTE_ADD_USER = 'user/adduser';
export const ROUTE_EDIT_USER = 'user/:id/edit';

//Security Role
export const ROUTE_SECURITY_ROLE = 'securityrole';
export const ROUTE_ADD_SECURITY_ROLE = 'securityrole/addsecurityrole';
export const ROUTE_UPDATE_SECURITY_ROLE = 'securityrole/:id/edit';

//Search Vendor
export const ROUTE_SEARCH_VENDOR = 'searchvendor';
export const ROUTE_ADD_VENDOR = 'searchvendor/addvendor';
export const ROUTE_VIEW_VENDOR = 'searchvendor/:id/view';
export const ROUTE_EDIT_VENDOR = 'searchvendor/:id/edit';

//Doctor Upload
export const ROUTE_DOCTOR_UPLOAD = 'doctorupload';
//PAYMENT Upload
export const ROUTE_PAYMENT_UPLOAD = 'paymentupload';

//Download Report
export const ROUTE_REPORT_DOWNLOAD = 'reportdownload';

export const ROUTE_CREATE_EVENT = 'createvent';
export const ROUTE_VENDOR_CONFERENCE_EVENT = 'vendorconferenceevent';

export const ROUTE_ADD_EVENT_CREATION = 'createvent/addeventcreation';
export const ROUTE_ADD_VENDOR_CONFERENCE_EVENT = 'vendorconferenceevent/addvendorconferenceevent';

export const ROUTE_VIEW_EVENT_CREATION = 'createvent/:id/view';
export const ROUTE_VIEW_VENDOR_EVENT_CREATION = 'vendorconferenceevent/:id/viewcreate';

//Maps to CloseEvent
export const ROUTE_EDIT_EVENT_CREATION = 'createvent/:id/edit';
//TODO this io not there

export const ROUTE_APPROVE_REJECT_EVENT_CREATION = 'createvent/:id/approvereject';
export const ROUTE_APPROVE_REJECT_VENDOR_CONFERENCE_EVENT = 'vendorconferenceevent/:id/approvereject';

export const ROUTE_APPROVE_REJECT_EVENT_CLOSURE = 'createvent/:id/approverejectclosure';
export const ROUTE_APPROVE_REJECT_VENDOR_EVENT_CLOSURE = 'vendorconferenceevent/:id/approverejectclosure';

export const ROUTE_RESUBMIT_CLOSURE_EVENT_CREATION = 'createvent/:id/resubmitclosure';
//TODO this io not there
/*export const ROUTE_RESUBMIT_CLOSURE_VENDOR_EVENT_CREATION = 'vendorconferenceevent/:id/resubmitclosur';*/
export const ROUTE_RESUBMIT_CLOSURE_VENDOR_EVENT_CREATION = 'vendorconferenceevent/:id/resubmitclosure';
export const ROUTE_APPROVE_REJECT_EVENT_CLOSURE_FINANCE = 'createvent/:id/approverejectclosurefinance';
export const ROUTE_APPROVE_REJECT_VENDOR_EVENT_CLOSURE_FINANCE = 'vendorconferenceevent/:id/approverejectclosurefinance';

export const ROUTE_VALIDATE_EVENT_CREATION_FINANCE = 'createvent/:id/validate';
export const ROUTE_VIEW_VENDOR_EVENT_VALIDATE = 'vendorconferenceevent/:id/validate';

export const ROUTE_UPDATE_ADVANCE_PAYMENT_EVENT_CREATION_FINANCE = 'createvent/:id/updateadvancepayment';
export const ROUTE_UPDATE_ADVANCE_PAYMENT_VENDOR_FINANCE = 'vendorconferenceevent/:id/updateadvancepayment';

export const ROUTE_REPORT_CLOSE_EVENT_CREATION = 'createvent/:id/reportclose';
export const ROUTE_REPORT_CLOSE_VENDOR_EVENT_CREATION = 'vendorconferenceevent/:id/reportclose';

export const ROUTE_EDIT_VENDOR_EVENT_CREATION = 'vendorconferenceevent/:id/resubmitcreate';
//TODO this is not there

export const ROUTE_SERVICE_AGREEMENT_UPLOAD = 'serviceagreement';

export const ROUTE_PASSBOOK ='passbook';
